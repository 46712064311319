export class CrossReferenceUtils {
  static getFormatName(formatName: string): string {
    if(formatName === "abovebelow") return "Above / Below";
    if(formatName === "paragraph") return "Paragraph";
    if(formatName === "number") return "Number";
    if(formatName === "fullnumber") return "Number (with context)";
    if(formatName === "relativenumber") return "Number (relative)";
    return ""
  }

  static convertToHtml(format: string) {
    format = format.replace(/#\{paragraph}/g, `&#8203;<crf contenteditable="false" value="paragraph" title="[Click to remove]">&#65279;${this.getFormatName("paragraph")}&#65279;</crf>&#8203;`)
    format = format.replace(/#\{abovebelow}/g, `&#8203;<crf contenteditable="false" value="abovebelow" title="[Click to remove]">&#65279;${this.getFormatName("abovebelow")}&#65279;</crf>&#8203;`)
    format = format.replace(/#\{number}/g, `&#8203;<crf contenteditable="false" value="number" title="[Click to remove]">&#65279;${this.getFormatName("number")}&#65279;</crf>&#8203;`)
    format = format.replace(/#\{fullnumber}/g, `&#8203;<crf contenteditable="false" value="fullnumber" title="[Click to remove]">&#65279;${this.getFormatName("fullnumber")}&#65279;</crf>&#8203;`)
    format = format.replace(/#\{relativenumber}/g, `&#8203;<crf contenteditable="false" value="relativenumber" title="[Click to remove]">&#65279;${this.getFormatName("relativenumber")}&#65279;</crf>&#8203;`)
    return format;
  }

  static convertToFormat(html: string) {
    html = html.replace(/<crf[^>]+value="paragraph"[^>]+>.*?<\/crf>/g, "#{paragraph}")
    html = html.replace(/<crf[^>]+value="abovebelow"[^>]+>.*?<\/crf>/g, "#{abovebelow}")
    html = html.replace(/<crf[^>]+value="number"[^>]+>.*?<\/crf>/g, "#{number}")
    html = html.replace(/<crf[^>]+value="fullnumber"[^>]+>.*?<\/crf>/g, "#{fullnumber}")
    html = html.replace(/<crf[^>]+value="relativenumber"[^>]+>.*?<\/crf>/g, "#{relativenumber}")
    html = html.replace(/\u200B/g,'');
    return html;
  }

}

