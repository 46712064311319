import {type Ref, ref, watch} from "vue";
import _debug from "debug"
const debug = _debug("app:resize-observer")

// Make a Ref<Element>'s sizing reactive
export const useResizeObserver = (element: Ref<Element>) => {
  const width = ref(element.value?.clientWidth ?? 0)
  const height = ref(element.value?.clientHeight ?? 0)

  const resizer = new ResizeObserver(([entry]) => {
    width.value = entry.contentRect.width
    height.value = entry.contentRect.height
    debug(`Width changed to ${width.value}, Height changed to ${height.value}`)
  })

  watch(element, (value, oldValue) => {
    if(value) {
      width.value = value.clientWidth
      height.value = value.clientHeight
      resizer.observe(value)
      debug("Observing element", value)
    }

    if(oldValue) {
      resizer.unobserve(oldValue)
      debug("Unobserving element", value)
    }
  }, {flush: 'post', immediate: true})

  return {width, height}
}