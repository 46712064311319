import {javascript} from "@api/all";
import {getCurrentTemplateId, useTemplateStore} from "@stores/generic/template.store";
import {useTemplateVersionStore} from "@stores/generic/templateVersion.store";
import {StoreMode, useHydration} from "@stores/utils";
import {defineStore, getActivePinia} from "pinia";
import {ref} from "vue";

export type TemplateViewType = ReturnType<typeof useTemplateVersionStore>
export type NonHydratedTemplateViewType = Partial<ReturnType<typeof createTemplateViewStore>>
export const allTemplateViewStores = ref<Array<TemplateViewType>>([])

type ResponseData = {
  localized_numbers: {
    normal: { ordinal: Record<number, string> },
    text: { ordinal: Record<number, string>, normal: Record<number, string> }
  }
} & Backend.Models.Model

export const createTemplateViewStore = (uniqueId: string | number) => {
  const templateViewStore = defineStore('templateView-store-' + String(uniqueId), () => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    const hydration = useHydration<ResponseData>(javascript.templateView)
    allTemplateViewStores.value.push(templateViewStore as unknown as TemplateViewType)

    const localizeNumbers = (num: number, ordinal = false, text = false) => {
      if(text) {
        if(ordinal) return hydration.hydratedData.value!.localized_numbers.text.ordinal[num] || String(num)
        else return hydration.hydratedData.value!.localized_numbers.text.normal[num] || String(num)
      } else {
        if(ordinal) return hydration.hydratedData.value!.localized_numbers.normal.ordinal[num] || String(num)
        else throw new Error("Invalid localizeNumber call")
      }
    }

    // Set default values for the store
    const $default = async () => {
      await hydration.hydrate({template_id: 'new'}, ['localized_numbers'])
      useTemplateStore(getActivePinia()).$default()
      useTemplateVersionStore(getActivePinia()).$default()
      hydration.storeMode.value = StoreMode.NewData
    }

    return {
      ...hydration,
      async hydrate() {
        await hydration.hydrate({ template_id: getCurrentTemplateId() }, ['localized_numbers'])
        await useTemplateStore(getActivePinia()).hydrateById(getCurrentTemplateId(), ['id', 'current_version_id'])
        const currentVersionId = useTemplateStore(getActivePinia()).currentVersionId
        await useTemplateVersionStore(getActivePinia())
          .hydrateById(currentVersionId, ['id', 'docx_settings', 'avv_participants_raw'])
      },
      $default,
      localizeNumbers,
    }
  })
  return templateViewStore
}

export const useTemplateViewStore = createTemplateViewStore('current')