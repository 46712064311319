<template>
  <teleport to="#editor-dialogs" :disabled="!windowMode">
    <div
      ref="element"
      :class="{
        'bg-white rounded shadow': true,
        'overflow-y-auto max-h-[700px] max-w-[70vw]':
          props.type === 'condition',
        'mx-auto absolute shadow-md': windowMode,
        'ml-2': !windowMode,
        [props.class ?? '']: true
      }"
      :style="{
        'max-h-[80%] max-w-[70%] overflow-auto':
          props.type === 'condition' && windowMode,
        willChange: 'transform',
        minWidth: windowMode ? 'max(30%, 500px)' : '',
        [props.style]: true,
        'z-index': windowMode ? 1000 : 5 + (focused ? 1 : 0)
      }"
      role="button"
      tabindex="0"
      @focusin="onFocusIn"
      @focusout="onFocusOut"
      @keydown.space.enter="emit('click')"
      @click.capture="emit('click')"
    >
      <div
        class="border-t-8 border-primary-500 rounded-t"
        role="button"
        tabindex="0"
        :class="{
          dragCursorClasses,
          'top-0 z-[1000] sticky': props.type === 'condition'
        }"
        @mousedown="onMouseDown"
      />
      <div class="flex flex-col p-4">
        <div class="flex items-center gap-2">
          <h3
            class="flex-initial font-bold avv-primary-color select-none"
            title="Click to minimize/restore or drag to move"
            :class="dragCursorClasses"
            role="button"
            tabindex="0"
            @mousedown="onMouseDown"
            @keydown.space.enter="onMinimize"
            @mouseup.left="onMinimize"
            v-text="props.title"
          />
          <div class="flex-1" />
          <slot name="options" />
          <button
            v-if="props.sidebar"
            type="button"
            :title="windowMode ? `Move to sidebar` : `Move to window`"
            class="flex-initial select-none material-icons"
            @click.left="toggleWindow"
            v-text="windowMode ? `tab_unselected` : `open_in_new`"
          />
          <button
            v-if="props.closeable"
            type="button"
            title="Close window"
            class="flex-initial select-none material-icons"
            @click.left="emit('close')"
          >
            close
          </button>
        </div>
        <div v-show="!minimized">
          <slot
            v-bind="$attrs"
            :dialog-width="element?.clientWidth ?? 0"
            :dialog-height="dialogHeight"
          />
        </div>
      </div>
    </div>
  </teleport>
</template>
<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  inheritAttrs: false
})
</script>
<script lang="ts" setup>
import { ref, watch, nextTick, computed } from 'vue'
import { useResizeObserver } from '~/features/_abstract/utils/resize-observer'
import { useDraggable } from './others/draggable'
import _debug from 'debug'
const debug = _debug('app:dialogue')
const emit = defineEmits(['close', 'minimize', 'click'])
const props = withDefaults(
  defineProps<{
    title: string
    type?: string
    closeable?: boolean
    minimizable?: boolean
    startMinimized?: boolean
    isNew?: boolean
    sidebar?: boolean
    startCentered?: boolean
    class?: string
    style?: string
  }>(),
  {
    closeable: true,
    minimizable: false,
    startMinimized: false,
    isNew: false,
    sidebar: false,
    startCentered: true,
    class: '',
    style: ''
  }
)

const windowMode = ref(props.isNew || !props.sidebar)
const element = ref<HTMLElement>()

const { onMouseDown, reset, isDragging, moveToCenter } = useDraggable(
  element,
  windowMode
)

const minimized = ref(props.startMinimized)
const onMinimize = () => {
  if (isDragging.value) return
  minimized.value = !minimized.value
  emit('minimize', minimized.value)
}

const toggleWindow = () => {
  windowMode.value = !windowMode.value
  if (!windowMode.value) reset()
  else void nextTick(() => moveToCenter()) // next-tick due teleport
}

defineExpose({
  toggleWindow,
  moveToCenter,
  windowMode,
})

const dialogSpacing = 150

const { height: dialogHeight } = useResizeObserver(element)
const { height: pageHeight } = useResizeObserver(ref(document.body))

const isOverflowing = computed(() => {
  return (
    Math.round(dialogHeight.value) >=
    Math.round(pageHeight.value - dialogSpacing)
  )
})

watch(isOverflowing, (value) => {
  if (!windowMode.value || props.sidebar) return

  debug(
    `Dialogue ${props.title} is ${value ? 'overflowing' : 'not overflowing'}`
  )
  if (value) {
    element.value!.style.height = `${pageHeight.value - dialogSpacing}px`
    element.value!.style.overflowY = 'scroll'
  } else {
    element.value!.style.removeProperty('height')
    element.value!.style.removeProperty('overflow-y')
  }
  moveToCenter()
})

watch(
  element,
  () => {
    if (props.startCentered && !props.sidebar) {
      moveToCenter()
    }
  },
  { flush: 'post' }
)

let dialogsElement = document.getElementById('editor-dialogs')
if (dialogsElement == null) {
  dialogsElement = document.createElement('div')
  dialogsElement.setAttribute('id', 'editor-dialogs')
  document.body.prepend(dialogsElement)
}

const dragCursorClasses = computed(() => {
  return {
    'cursor-move': windowMode.value,
    'cursor-pointer': !windowMode.value
  }
})

const focused = ref(false)
const onFocusIn = () => (focused.value = true)
const onFocusOut = () => (focused.value = false)
</script>
