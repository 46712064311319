import {AvvNodeGrammar, Delta, DeltaParser, generateTrackingChanges} from "@avvoka/editor";
import {getAcceptedDelta, getRejectedDelta, HtmlParser, postProcessTrackingChanges} from "@avvoka/editor";
import { NegoUtils } from "./nego";

import ExportApi from '@api/Documents/ExportApi';

export class CompareUtils {

  static setCompareBannerText(text: string) {
    const element = document.querySelector('p.compare');
    if(element) {
      element.textContent = text;
    }
  }

  static limitHeight() {
    const editor = EditorFactory.get("compare").get();
    const mountPoint = editor.scroll.node.parentElement!.parentElement!;
    const sidebar = mountPoint.querySelector(".avv-sidebar") as HTMLElement;
    if(sidebar) sidebar.style.height = `${window.innerHeight - sidebar.getBoundingClientRect().top}px`;
    const container = mountPoint.querySelector(".avv-container") as HTMLElement;
    if(container) container.style.height = `${window.innerHeight - container.getBoundingClientRect().top}px`;
    const wrapper = mountPoint.querySelector(".avv-container-wrap") as HTMLElement;
    if(wrapper) wrapper.style.height = `${window.innerHeight - wrapper.getBoundingClientRect().top}px`;
  }

  static hideCompareEditor () {
    const editors = Array.from(document.querySelectorAll<HTMLElement>('.avv-editor'))
    editors.forEach((editor) => {
      const wrapper = editor.parentElement as HTMLElement
      const container = editor.querySelector('.avv-container-wrap') as HTMLElement
      const childContainer = container.querySelector('.avv-container') as HTMLElement
      const sidebar = editor.querySelector('.avv-sidebar') as HTMLElement
      wrapper.classList.remove('multiple-editors')
      editor.classList.remove('multiple-editors')
      if(editor.id === 'compare') editor.style.display = 'none'
      if(editor.id === 'editor') {
        sidebar.style.display = 'flex'
        editor.style.border = 'none'
      }
      container.style.gridTemplateColumns = '804px 400px'
      childContainer.style.width = ''
      if(sidebar) sidebar.style.position = ''
    })
    CompareUtils.emit()
  }

  static handleUI() {
    const editors = Array.from(document.querySelectorAll('.avv-editor')) 
    editors.forEach(editor => {
      const wrapper = editor.parentElement as HTMLElement
      const container = editor.querySelector('.avv-container-wrap') as HTMLElement
      const childContainer = container.querySelector('.avv-container') as HTMLElement
      const sidebar = editor.querySelector('.avv-sidebar') as HTMLElement
      const tabContent = document.querySelector('.tab-content.p-top-0') as HTMLElement
      wrapper.classList.add('multiple-editors')
      editor.classList.add('multiple-editors')
      if(editor.id === 'compare') {
        editor.style.display = 'block'
        editor.style.border = ''
        if(sidebar) {
          container.style.gridTemplateColumns = '1fr 400px'
          sidebar.style.display = 'flex'
        }
      }
      if(editor.id === 'editor') {
        // sidebar.style.display = 'none'

        editor.style.border = ''
        editor.style.width = ''
        container.style.gridTemplateColumns = '1fr 400px'
      }
      childContainer.style.width = '100%'
      if(sidebar) sidebar.style.position = 'unset'
      if(tabContent) tabContent.classList.remove('p-top-0')
    })
  }

  static async createCompareEditor(callback?: (editorId: string) => void) {
    return new Promise<void>(resolve => {
      EditorFactory.create({
        id: "compare",
        bounds: "#compare",
        mode: "document",
        readOnly: true,
        loadComments: true,
        rtl: false,
        onCreate(ed) {
          CompareUtils.handleUI()
          if(typeof callback === 'function') callback(ed)
          resolve()
        }
      })

      EditorFactory.get("compare").get().onReady.subscribe(({ready}) => {
        if(ready) CompareUtils.limitHeight()
      })
      window.addEventListener("resize", CompareUtils.limitHeight);
    })
  }

  static getOptions (draftId: number) {
    return {
      'type': 'post',
      'url': `/load/${draftId}`
    }
  }

  static async getTemplateBody (documentId: number): Promise<{ comments: Array<Backend.Models.Comment>, body: string }> {
    return {
      comments: [],
      body: await ExportApi.create({ data: { document_ids: documentId, formats: 'document_html_draft' }})
    }
  }

  static async getBody (draftId: number): Promise<{comments: Array<Backend.Models.Comment>, body: string}> {
    const options = CompareUtils.getOptions(draftId)
    return await $.ajax(options) 
  }

  static async handleComparing (html1: string, html2: string, text: string) {
    if(!EditorFactory.exists("compare")) await CompareUtils.createCompareEditor()
    const compareEditor = EditorFactory.get("compare").get()

    const delta1 = getRejectedDelta(HtmlParser.parse(html1).toDelta() as Delta);
    const delta2 = getAcceptedDelta(HtmlParser.parse(html2).toDelta() as Delta);

    const node1 = DeltaParser.parse(delta1);
    const node2 = DeltaParser.parse(delta2);

    const updateDelta = generateTrackingChanges(node1, node2, EditorFactory.main.negotiation.user);
    const composedDelta = node2.toDelta().compose(updateDelta)
    postProcessTrackingChanges({ composedDelta, beforeDelta: delta1, afterDelta: delta2})
    const resultNode = DeltaParser.parse(composedDelta)
    AvvNodeGrammar.applyNegoGrammar(resultNode);

    compareEditor.load(resultNode.toHTML(false));
    CompareUtils.handleUI()
    CompareUtils.setCompareBannerText(text);
  }

  static loadComments(comments: Array<Backend.Models.Comment.Parsed>) {
    comments.forEach(comment => {
      comment.message.forEach(entry => {
        const RE = /<user-mention[^>]*participant="(\d+)"[^>]*>/g
        const mentions = []
        for(const match of entry.text.matchAll(RE)) {
          mentions.push(parseInt(match[1]))
        }
        entry.mentions = mentions;
        entry.author = parseInt(entry.author as any);
      })
    })

    EditorFactory.getEntry("compare").get().bridge!.overrideComments = comments
  }
  
  static async displayVersion(draft: Backend.Models.Draft) {
    if (!draft) return false
    const { body, comments } = await CompareUtils.getBody(draft.id)
    const versionName = draft.name ?? `${window.localizeText('document.compare.viewing')} ${draft.version}`
    const result = await CompareUtils.handleVersion(body, versionName)
    CompareUtils.loadComments(comments);
    return result
  }

  static async handleVersion (html: string, text: string) {
    if(!EditorFactory.exists("compare")) await CompareUtils.createCompareEditor()
    const compareEditor = EditorFactory.get("compare").get()
    compareEditor.load(html);
    CompareUtils.handleUI()
    CompareUtils.setCompareBannerText(text);
  }

  static getVersion(draftId: number): "Cannot load version" | number {
    const element = document.querySelector(`#versions > a[data-id="${draftId}"] > b`)
    if(!element) return "Cannot load version";
    return +(element.textContent ?? 0);
  }

  static saveVersion(reason: "save" | "publish" | "uploaded" = "save", reload = true): Promise<void> | never {
    return new Promise<void>(resolve => {
      if(!EditorFactory.exists("draft")) reload ? location.reload() : resolve()
      else {
        $_sec.ajax({
          type: "POST",
          url: "/data",
          data: {
            document: EditorFactory.get("draft").get().negotiation.id,
            reason: reason
          },
          success: () => reload ? location.reload() : resolve()
        })
      }
    }) 
  }

  static loadCurrentVersion() {
    window.avv_dialog({confirmMessage: "Are you sure you want to load this version? This will replace the current content in the editor.", confirmCallback: async (value) => {
      if(value) {
        const editor = EditorFactory.get("draft").get()
        let success = true
        if(NegoUtils.isUnlocked(editor)) success = await NegoUtils.unlock(editor)
        if(!NegoUtils.isLockedToMe(editor) || !success) return
        const compareEditor = EditorFactory.get("compare").get()
        EditorFactory.main.load(compareEditor.getDelta())
        await EditorFactory.main.negotiation.asyncSubmitDeltaChange()
        await CompareUtils.saveVersion()
      }
    }})
  }

  static onCloseListeners: Array<() => void> = []
  static onClose(cb: () => void) {
    CompareUtils.onCloseListeners.push(cb)
  }

  static emit(){
    CompareUtils.onCloseListeners.forEach(cb => cb())
  }
}
